<template>
  <div class="forget-password">
    <el-card shadow="never" class="card">
      <p class="header">重置密码</p>
      <div class="formBox">
        <Steps :current="current" size="small">
          <Step title="重设新密码"></Step>
          <Step title="重设密码成功"></Step>
        </Steps>
      </div>
      <div v-show="current == 0" class="forget-content">
        <el-form ref="form1" :model="form1" label-width="80px" :rules="rules">
          <el-form-item label="手机号" label-width="150px" prop="phone">
            <el-input
              v-model="form1.phone"
              placeholder="请输入手机号码"
              prefix-icon="el-icon-user"
              class="phone-input"
            ></el-input>
          </el-form-item>
          <el-form-item label="图形验证码" label-width="150px" prop="code">
            <el-input
              v-model="form1.code"
              placeholder="图形验证码"
              prefix-icon="iconfont icon-yanzhengyanzhengma"
              style="width: 213px"
            ></el-input>
            <img
              :src="verifyUrl"
              alt="verifyCode"
              class="verifyImg"
              @click="this.changeVerifyCode"
            />
          </el-form-item>
          <el-form-item label="验证码" label-width="150px" prop="code1">
            <el-input
              v-model="form1.code1"
              placeholder="请输入收到验证码"
              prefix-icon="iconfont icon-yanzhengyanzhengma"
              style="width: 213px"
              type="code1"
            ></el-input>
            <span class="getCode" @click="_getCode">
              {{ show ? '获取验证码' : `${count}s后重新获取` }}
            </span>
            <p>验证码两分钟内有效，超时请重新获取</p>
          </el-form-item>
          <el-form-item label="新密码" label-width="150px" prop="passWord">
            <el-input
              v-model="form1.passWord"
              placeholder="请入6-16位大小写字母、数字、符号中任意两种组合的密码"
              class="phone-input"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="重复新密码" label-width="150px" prop="passWord2">
            <el-input
              v-model="form1.passWord2"
              placeholder="请入6-16位大小写字母、数字、符号中任意两种组合的密码"
              class="phone-input"
              type="password"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div v-show="current == 2" class="forget-content">333</div> -->
      <div class="next">
        <Button type="primary" @click="next(form1.code1)">下一步</Button>
      </div>
    </el-card>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div
        class="icon iconfont icon-chenggong"
        style="font-size: 50px; color: #1fbd9d; margin-left: 120px"
      ></div>
      <p style="margin-left: 100px">密码重置成功</p>
      <p style="margin-left: 60px">下次登录时请使用新的密码</p>
      <p style="margin-left: 80px">{{ `${second}秒后将自动关闭该提示` }}</p>
      <p slot="footer" class="dialog-footer" style="margin-right: 150px">
        <el-button type="primary" @click="goLogin">返回登录</el-button>
      </p>
    </el-dialog>
  </div>
</template>

<script>
import { getVerifyCode, getForgetCode, getAuth } from './api'

const TIME_COUNT = 120
export default {
  data() {
    //验证是否是正确的手机格式
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      } else {
        const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确格式的手机号'))
        }
      }
    }
    // 验证正确的密码格式: 6-16位，有数字和字母
    const passwordTest = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('密码不能为空'))
      } else {
        const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入6-16位并且必须包含数字和字母的密码'))
        }
      }
    }
    // 验证二次输入密码
    const validatePwd2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form1.passWord) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }

    return {
      current: 0,
      show: true,
      count: '',
      timer: null,
      time: null,
      verifyUrl: '',
      dialogVisible: false,
      second: '',
      form1: {
        phone: '',
        code: '',
        code1: '',
        passWord: '',
        passWord2: '',
      },
      rules: {
        phone: [
          { validator: validatePhone, required: true, trigger: 'change' },
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'change' }],
        code1: [{ required: true, message: '请输入验证码', trigger: 'change' }],
        passWord: [
          { validator: passwordTest, required: true, trigger: 'change' },
        ],
        passWord2: [
          {
            required: true,
            validator: validatePwd2,
            trigger: 'blur',
          },
        ],
      },
    }
  },
  mounted() {
    this._getVerifyCode()
  },
  methods: {
    next(code1) {
      if (this.current === 0) {
        this.$refs.form1.validate(async valid => {
          if (valid) {
            try {
              const params = { password: this.form1.passWord }
              const result = await getAuth(code1, params)
              if (!result) {
                this.$message.success('密码修改成功')
                this.current += 1
                this.dialogVisible = true
                this.second = 3
                this.time = setInterval(() => {
                  if (this.second > 0 && this.second <= 3) {
                    this.second--
                    if (this.second <= 0) {
                      this.$router.push('/login')
                    }
                  } else {
                    clearInterval(this.time)
                    this.time = null
                  }
                }, 1000)
              }
            } catch (err) {
              console.error(err)
            }
          }
        })
      }
    },
    async _getVerifyCode() {
      try {
        this.verifyUrl = await getVerifyCode()
      } catch (err) {
        console.error(err)
      }
    },
    async _getCode() {
      if (!this.show || !this.form1.phone || !this.form1.code) {
        return
      }
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
        try {
          const params = {
            username: this.form1.phone,
            code: this.form1.code,
          }
          await getForgetCode(params)
          this.$message.success('发送成功，请注意查收')
        } catch (err) {
          console.error(err)
        }
      }
    },

    changeVerifyCode() {
      this._getVerifyCode()
    },

    handleClose() {
      this.dialogVisible = false
    },

    goLogin() {
      this.dialogVisible = true
      this.$router.push('/login')
    },
  },
}
</script>

<style scoped lang="scss">
.forget-password {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 32px 315px 112px 315px;
  .card {
    width: 810px;
    height: 570px;
    position: relative;

    .header {
      position: relative;
      height: 64px;
      line-height: 22px;
      width: 100%;
      font-size: 16px;
      color: #333;
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 10px;
        height: 32px;
        background: #2c68ff;
        top: -10px;
        left: -20px;
      }
    }

    .formBox {
      display: flex;
      align-items: center;
      justify-content: center;
      .ivu-steps {
        width: 364px;
      }
    }

    .next {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 520px;
      left: 210px;
      .ivu-btn {
        width: 218px;
        height: 44px;
      }
    }

    .forget-content {
      // margin-left: 188px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 51px;
      .phone-input {
        width: 349px;
        height: 44px;
      }

      .getCode {
        display: inline-block;
        width: 128px;
        height: 44px;
        background: #147fd2;
        border-radius: 8px;
        color: #ffffff;
        text-align: center;
        line-height: 44px;
        font-weight: 400;
        margin-left: 8px;
        cursor: pointer;
      }

      .verifyImg {
        display: inline-block;
        width: 128px;
        height: 44px;
        position: absolute;
        top: 0;
        margin-left: 8px;
      }

      p {
        font-size: 12px;
      }
    }

    .el-dialog {
      display: flex;
      .icon {
        margin-left: 500px;
      }
      p {
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
